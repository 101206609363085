<template>
    <div>
      <v-card-title class="display-1 mb-2">{{t('$vuetify.serviceCenter.requestCharger')}}</v-card-title>
      <v-card-subtitle v-if="!isSaved">{{t('$vuetify.serviceCenter.subReviewOrder')}}</v-card-subtitle>
       <v-container v-if="!isSaved">
        <v-row>
          <v-card flat class="ma-4">
            <v-card-title> {{t('$vuetify.serviceCenter.orderDetails')}}</v-card-title>
            <v-card-text >
              {{t('$vuetify.serviceCenter.chargerType')}} : {{elm.customfield_15729.id | toStringFields}}<br>
              <span v-if="elm.customfield_15730.id !== ''">{{t('$vuetify.serviceCenter.mountingType')}} : {{elm.customfield_15730.id  | toStringFields}}<br></span>
              <span v-if="elm.customfield_15736.id !== ''">{{t('$vuetify.serviceCenter.chargerType')}} : {{elm.customfield_15736.id | toStringFields}}<br></span>
              <span v-if="elm.customfield_15731.id !== ''">{{t('$vuetify.serviceCenter.installationType')}} : {{elm.customfield_15731.id | toStringFields}}<br></span>
              <span v-if="elm.customfield_15732.id !== ''">{{t('$vuetify.serviceCenter.sub')}} : {{elm.customfield_15732.id | toStringFields}}<br></span>
              <span v-if="elm.customfield_15733.id !== ''">{{t('$vuetify.serviceCenter.dynamicLoadBalancing')}} : {{elm.customfield_15733.id | toStringFields}}<br></span>
            </v-card-text>
          </v-card>
        </v-row>
      <v-row>
        <v-card flat class="ma-4" xs6>
          <v-card-title> {{t('$vuetify.serviceCenter.invAddress')}}:  </v-card-title>
          <v-card-text v-if="!isSaved">
            {{elm.customfield_15407}} <br>
            {{elm.customfield_13200}} <br>
            {{elm.customfield_15746}} <br>
            {{elm.customfield_15747}} {{elm.customfield_15748}}
          </v-card-text>
        </v-card>
        <v-card flat class="ma-4" xs6>
          <v-card-title> {{t('$vuetify.serviceCenter.delAddress')}}:  </v-card-title>
          <v-card-text v-if="!isSaved">
            {{elm.customfield_15407}} <br>
            {{elm.customfield_13200}} <br>
            {{elm.customfield_15742}} <br>
            {{elm.customfield_15743}} {{elm.customfield_15744}}
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-card flat class="ma-4" xs12>
          <v-card-title>{{t('$vuetify.serviceCenter.contactDetails')}}: </v-card-title>
          <v-card-text v-if="!isSaved">
            {{t('$vuetify.serviceCenter.email')}} : {{elm.customfield_15749}} <br>
            {{t('$vuetify.serviceCenter.phone')}} : {{elm.customfield_12508}} <br>
          </v-card-text>
        </v-card>
      </v-row>
       </v-container>
      <v-form ref="form" v-model="isFormValid" lazy-validation v-if="!isSaved">
        <v-card-text>
          <v-row>
            <v-textarea
              class="mx-2"
              :validate-on-blur="false"
              :error="error"
              @keyup.enter="submit"
              @change="resetErrors"
              name="information"
              :label="t('$vuetify.serviceCenter.extraInfoField')"
              v-model="payload"
            ></v-textarea>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-text v-if="isSaved">
        Thank you for your request. We will contact you soon.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn v-if="!isSaved"
            color="grey"
            text
            @click="previousStep()"
        >
            <v-icon>mdi-chevron-left</v-icon>
            {{t('$vuetify.generic.back')}}
        </v-btn>
        <v-btn
            v-if="!isSaved"
            color="success"
            text
            @click="nextStep()"
            :loading="isSaveRequest"
            :disabled="isSaving"
        >
            {{t('$vuetify.generic.save')}}
        </v-btn>
        <v-btn
            v-if="!isSaved"
            color="red"
            text
            @click="cancel"
        >
            {{t('$vuetify.generic.cancel')}}
        </v-btn>
        <v-btn
          color="primary"
          text
          v-if="isSaved"
          @click="closeRQ"
        >
          {{t('$vuetify.generic.close')}}
        </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestApi from '../../../services/RequestApi'

export default {
  data () {
    return {
      isSaved: false,

      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,
      isSaveRequest: false,
      isSaving: false,

      isDiffAdrs: false,
      langs: ['en', 'de'],
      payload: '',

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      isVerified: false,
      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  computed: {
    ...mapGetters({ elm: 'requestsState/elmState', price: 'requestsState/price', totPrice: 'requestsState/totalPrice' })
  },
  filters: {
    toStringFields (val) {
      let txtVal = ''
      switch (val) {
        case '13840': txtVal = 'Wall-mounted'; break // Mounting Type
        case '13841': txtVal = 'Pole-mounted'; break // Mounting Type
        case '13845': txtVal = 'Subscription@Home'; break // Subscription Type
        case '13846': txtVal = 'No subscription'; break // Subscription Type
        case '13847': txtVal = 'Yes'; break // Load balancing
        case '13848': txtVal = 'No'; break // Load balancing
        case '13837': txtVal = 'Smart cable 1 phase'; break // Charger Type
        case '13838': txtVal = 'Smart cable 3 phase'; break // Charger Type
        case '13839': txtVal = 'Smart cable (unknown)'; break // Charger Type
        case '13849': txtVal = 'Socket 1 phase'; break // Charger Type
        case '13850': txtVal = 'Socket 3 phase'; break // Charger Type
        case '13851': txtVal = 'Socket (unknown)'; break // Charger Type
        case '13842': txtVal = 'Standard installation'; break // Installation Type
        case '13843': txtVal = 'Mounting and testing only'; break // Installation Type
        case '13844': txtVal = 'Unknown'; break // Installation Type
        case '13854': txtVal = '1x 230V'; break // Power connection
        case '13855': txtVal = '3x 230V'; break // Power connection
        case '13856': txtVal = '3x 400+N'; break // Power connection
        default:
          break
      }
      return txtVal
    }
  },
  methods: {
    nextStep () {
      this.submit()
      // this.$store.commit('requestsState/setStep', 'ContactDetails')
    },
    previousStep () {
      this.$store.commit('requestsState/setPreviousStepName')
    },
    async save () {
      try {
        console.log('START SAVING')
        // this.isSaved = false
        // this.isSaving = false
        console.log(this.elm)
        const payload = this.elm
        payload.description = this.payload
        console.log(payload)
        const data = await new RequestApi().createChargePointRequest(payload)
        this.isSaveRequest = false
        this.isSaving = false
        this.isSaved = data.result.data.Status !== '400'
      } catch (err) {
        console.log(err)
      }
    },
    submit () {
      if (this.$refs.form.validate()) {
        // this.$store.commit('requestsState/setCompanyDetails', this.payload)
        this.isSaveRequest = true
        this.isSaving = true
        this.save()
      }
    },
    cancel () {
      this.cleanPayload()
      this.$store.commit('requestsState/setStep', 'AccountType')
      this.$emit('cancel')
    },
    cleanPayload () {
      this.resetErrors()
      this.payload = ''
    },
    closeRQ () {
      this.cleanPayload()
      this.$store.commit('requestsState/setStep', 'AccountType')
      this.$emit('closeRQ')
    },
    resetErrors () {
      this.error = false
      this.errorMessages = ''

      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
